<template>
  <div class="home">
    <c-c-water-mark :input-text="watermarkStr" ref="watermark" v-if="!isDesktop && globalSetting.enableWaterMark"/>
    <div class="header">
      <div class="brand-container" @click="handleGoBackHome" v-if="includesDM && !includesCC">
        <cc-iconfont :size="18" color="#FEFEFE" name="dm"></cc-iconfont>
      </div>
      <div class="layout-logo" @click="handleGoBackHome" v-if="includesCC && !includesDM">
        <img :src="require('../../assets/logo/header.png')" style="object-fit: cover;" width="137">
      </div>
      <div class="layout-logo" @click="handleGoBackHome" v-if="includesCC && includesDM">
        <img :src="require('../../assets/logo-rdp-white.svg')" style="object-fit: cover;" width="160">
      </div>
      <navbar style="flex:1"/>
      <div class="user-info" v-if="!isDesktop">
        <Dropdown @on-click="handleChangeProductCluster" transfer v-if="getCcProductClusterList.length > 0 && showLicenseSelect">
          <a href="javascript:void(0)" style="color: #fff;display: flex;justify-content: space-between;">
            <div><span :class="`dot-span ${currentProduct.status === 'ONLINE'?'online-span':'offline-span'}`"></span>{{ currentProduct.clusterDesc }}<span style="margin-left: 6px" v-if="currentProduct.status !== 'ONLINE'">{{ $t('yi-chang-0') }}</span></div>
            <div class="navbar-nav-nb" style="font-size: 14px;margin-left:4px" v-if="includesCC && currentProduct.status === 'ONLINE'">
              <span>{{$t('ban-ben-v')}}{{
                  currentProduct.config && currentProduct.config.productVersions.buildInfo.mainVersion
                }}{{$t('you-kuo-hao')}}</span>
            </div>
            <div style="margin-left: 4px"><Icon type="ios-arrow-down"></Icon></div>
          </a>
          <DropdownMenu slot="list">
            <DropdownItem :name="cluster.clusterCode" :selected="currentProduct.clusterCode===cluster.clusterCode" :key="cluster.clusterCode" v-for="cluster in getCcProductClusterList">
              <div style="height: 30px;line-height: 30px;text-align: center;display: flex;justify-content: space-between;">
                <div><span :class="`dot-span ${cluster.status === 'ONLINE'?'online-span':'offline-span'}`"></span>{{cluster.clusterDesc}}<span style="margin-left: 6px" v-if="cluster.status !== 'ONLINE'">{{ $t('yi-chang-0') }}</span></div>
                <div class="navbar-nav-nb-dropdown" style="font-size: 14px;margin-left:4px" v-if="includesCC && cluster.status === 'ONLINE'">
                  <span>{{$t('ban-ben-v')}}{{
                      cluster.config && cluster.config.productVersions.buildInfo.mainVersion
                    }}{{$t('you-kuo-hao')}}</span>
                </div>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
<!--        <a href="javascript:void(0)"  style="color: #fff;display: flex;justify-content: space-between;" v-if="getCcProductClusterList.length > 0 && !showLicenseSelect">-->
<!--          <div><span :class="`dot-span ${currentProduct.status === 'ONLINE'?'online-span':'offline-span'}`"></span>{{ currentProduct.clusterDesc }}<span style="margin-left: 6px" v-if="currentProduct.status !== 'ONLINE'">{{ $t('yi-chang-0') }}</span></div>-->
<!--        </a>-->
        <div class="navbar-nav-nb" style="font-size: 14px;" v-if="getCcProductClusterList.length === 0 && includesCC">
          <span style="margin-right: 20px">v{{
              ccGlobalSetting.productVersions && ccGlobalSetting.productVersions.buildInfo.mainVersion
            }}</span>
          <a :href="`${store.state.docUrlPrefix}/#price`" style="font-size:12px"
             target="_blank">{{ EDITIONS_I18N[ccGlobalSetting.productAuthType] }}&nbsp;</a>
          <span class="license-status-span"
                v-if="ccGlobalSetting.authCode && !ccGlobalSetting.authCode.success">
                <span @click="handleshowInactiveReason"
                       style="color: rgb(255, 24, 21)">{{ $t('wei-ji-huo') }}</span></span>
          <span class="license-status-span"
                v-if="ccGlobalSetting.authCode && ccGlobalSetting.authCode.success">
                <span @click="handleShowResourceInfo"><span
            style="color: rgb(82, 196, 26)">{{ $t('yi-ji-huo') }}</span>{{ $t('huan-sheng') }}<strong>{{
              Math.floor(ccGlobalSetting.authCode.remainingTimeMs / 1000 / 60 / 60 / 24)
            }}</strong>{{ $t('tian-0') }}<strong>{{
              Math.floor(ccGlobalSetting.authCode.remainingTimeMs / 1000 / 60 / 60 % 24)
            }}</strong>{{ $t('xiao-shi-0') }}</span></span>
        </div>
<!--        <a v-if="currentProd  uct.status === 'ONLINE'" @click="handleShowResourceInfo">{{ $t('xu-ke-zheng-xiang-qing') }}</a>-->
        <div class="navbar-nav-nb" style="font-size: 14px;" v-if="includesCC && currentProduct.status === 'ONLINE'" @click="handleShowResourceInfo">
          <span>{{ EDITIONS_I18N[currentProduct.config && currentProduct.config.productAuthType] }}&nbsp;</span>
          <span class="license-status-span"
                v-if="currentProduct.config && currentProduct.config.authCode && !currentProduct.config.authCode.success">
                <span style="color: rgb(255, 24, 21)">{{ $t('wei-ji-huo') }}</span></span>
          <span class="license-status-span"
                v-if="currentProduct.config && currentProduct.config.authCode && currentProduct.config.authCode.success">
                <span><span
                  style="color: rgb(82, 196, 26)">{{ $t('yi-ji-huo') }}</span>{{ $t('huan-sheng') }}<strong>{{
                    Math.floor(currentProduct.config && currentProduct.config.authCode.remainingTimeMs / 1000 / 60 / 60 / 24)
                  }}</strong>{{ $t('tian-0') }}<strong>{{
                    Math.floor(currentProduct.config && currentProduct.config.authCode.remainingTimeMs / 1000 / 60 / 60 % 24)
                  }}</strong>{{ $t('xiao-shi-0') }}</span></span>
        </div>
<!--        <a-select class="cluster-select" v-if="showCcProductCluster" v-model="currentProduct" :placeholder="$t('xuan-ze-chan-pin-ji-qun')" size="small" @change="handleSelectProduct"-->
<!--        >-->
<!--          <a-select-option v-for="cluster in productClusterList" :value="cluster.clusterCode" :key="cluster.id">{{ cluster.clusterDesc }}</a-select-option>-->
<!--        </a-select>-->
        <cc-iconfont :size="20" name="help" @click.native="handleGoHelp"/>
        <a-dropdown v-if="userInfo.menuItems&&userInfo.menuItems.includes('/ccsystem/console_job')"
                    :trigger="['click']">
        <span class="message-icon">
          <cc-iconfont :size="20" name="message"/>
          <span v-if="messageList.length>0" class="message-point"></span>
        </span>
          <div slot="overlay" class="message-list-container">
            <p class="title">{{ $t('xiao-xi-zhong-xin') }}</p>
            <div v-for="message in messageList" :key="message.id" class="message-item"
                 @click="handleGoMessageDetail(message.id)">
              <p>
                {{ CONSOLE_JOB_NAME[message.label] }}{{$t('shi-bai')}}
              </p>
              <p class="time">{{ message.gmtModified | formatTime('YYYY-MM-DD HH:mm:ss') }}</p>
            </div>
            <div class="message-footer" @click="handleGoMessage">
              {{ $t('cha-kan-geng-duo') }}
            </div>
          </div>
        </a-dropdown>
        <Dropdown @on-click="handleLangChange" transfer>
          <a href="javascript:void(0)"  style="width: 100px;color: #fff;">
            {{LANG_I18N[$i18n.locale]}}
            <Icon type="ios-arrow-down"></Icon>
          </a>
          <DropdownMenu slot="list">
            <DropdownItem name="zh-CN" :selected="$i18n.locale==='zh-CN'">
              <div style="height: 30px;line-height: 30px;text-align: center">中文</div>
            </DropdownItem>
            <DropdownItem name="en-US" :selected="$i18n.locale==='en-US'">
              <div style="height: 30px;line-height: 30px;text-align: center">English</div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <div class="domain">{{ userInfo.username }}</div>
        <div v-click-out-hide="hideMenu" class="avatar" @click="showMenu=!showMenu">
          <img :alt="$t('useravatar')" src="../../assets/head.png">
        </div>
        <div v-show="showMenu" class="menu">
          <div class="one">
            <div class="avatar">
              <img :alt="$t('useravatar')" src="../../assets/head.png">
            </div>
            <div class="domain">
              {{ userInfo.username }}
              <a-tag color="orange" class="parent-account-label" v-if="userInfo.accountType==='PRIMARY_ACCOUNT'">
                {{ $t('zhu') }}
              </a-tag>
            </div>
<!--            <span v-if="userInfo.accountType!=='PRIMARY_ACCOUNT'">-->
<!--              <a-popover v-model="showAccount" placement="left" trigger="hover">-->
<!--              <p slot="content">-->
<!--                {{ userInfo.loginAccount }}-->
<!--              </p>-->
<!--              <cc-iconfont style="margin-left: 10px;margin-right: 6px" name="account"-->
<!--                           color="#555555"-->
<!--                           :size="20"></cc-iconfont>-->
<!--            </a-popover>-->
<!--            <a @click="handleCopy(userInfo.loginAccount)">{{ $t('fu-zhi-zhang-hao') }}</a>-->
<!--            </span>-->
          </div>
          <div class="two">
            <div>{{$t('dian-hua')}}: {{ userInfo.phone }}</div>
            <div>{{$t('you-xiang')}}: {{ userInfo.email }}</div>
<!--            <div v-if="userInfo.accountType!=='PRIMARY_ACCOUNT'">{{$t('suo-shu-zhu-zhang-hao')}}: {{ userInfo.pusername }}({{userInfo.pemail}})</div>-->
          </div>
          <div class="three">
            <div @click="goUserCenter">
              <a-icon style="font-size:12px;color:#555555" type="user" />
              {{ $t('zhang-hu-zhong-xin') }}
            </div>
<!--            <div @click="goReset" v-if="userInfo.bindType !== 'LDAP'">-->
<!--              <cc-iconfont :size="12" color="#555555" name="password2"></cc-iconfont>-->
<!--              {{ $t('xiu-gai-deng-lu-mi-ma') }}-->
<!--            </div>-->
<!--            <div @click="_setOpPasswordModal" v-if="globalSetting.oppassword">-->
<!--              <cc-iconfont :size="12" color="#555555" name="password"></cc-iconfont>-->
<!--              {{ $t('xiu-gai-cao-zuo-mi-ma') }}-->
<!--            </div>-->
<!--            <div @click="goUserConfig" v-if="!userInfo.parentId">-->
<!--              <a-icon style="font-size:12px;color:#555555" type="setting"/>-->
<!--              {{ $t('xiu-gai-pei-zhi-0') }}-->
<!--            </div>-->
<!--            <div @click="_setCloudAKSKModal" v-if="!userInfo.parentId">-->
<!--              <cc-iconfont :size="12" color="#555555" name="fangwe"></cc-iconfont>-->
<!--              {{ $t('a-li-yun-fang-wen-quan-xian') }}-->
<!--            </div>-->
<!--            <div @click="_setApprovalProcessModal" v-if="!userInfo.parentId">-->
<!--              <cc-iconfont :size="12" color="#555555" name="shenpi"></cc-iconfont>-->
<!--              {{ $t('shen-pi-liu-cheng-fang-wen-quan-xian') }}-->
<!--            </div>-->
          </div>
          <div class="four" @click="logout">
            {{ $t('tui-chu-zhang-hao') }}
          </div>
        </div>
      </div>
      <div v-else style="display: flex;">
        <div class="version" style="display: flex;align-items: center;position: relative;">
          <a-button type="link" size="large" @click="checkVersion(true)" style="font-weight: bold;">
            {{ buildVersion }}<span style="color: red;" v-if="version.newVersion">{{ $t('new') }}</span>
          </a-button>
        </div>
        <a-tooltip trigger="hover" v-if="isOnline">
          <cc-iconfont :size="20" name="help" style="margin-right: 30px;"/>
          <template slot="title">
            <div style="display: flex;flex-direction: column;align-items: center;">
              <div style="margin-bottom: 5px;">{{ $t('jia-ru-wei-xin-jiao-liu-qun') }}</div>
              <img
                src="https://official-web-resource.oss-cn-hangzhou.aliyuncs.com/wechat-clouddm.png?time=1688610325842"
                :width="100" :height="100"/>
            </div>
          </template>
        </a-tooltip>
      </div>
    </div>
    <div class="user-expr-tip" v-if="userInfo.subAccountPwdValidDays!==null && userInfo.subAccountPwdValidDays < limitDays">
      {{$t('gen-ju-zhu-zhang-hao-she-zhi-de-mi-ma-shi-xiao-ce-lue', [userInfo.subAccountPwdValidDays + 1])}}
    </div>
    <div class="content-container" v-if="showChild">
      <router-view/>
    </div>
    <a-modal v-model="showDetailModal" :title="selectedCellDetail.column.property"
             v-if="showDetailModal" key="showDetailModal" :width="800">
      <!--      <div v-if="selectedCellDetail.row[selectedCellDetail.column.property]">-->
      <!--        *内容过长已截断，如需查看完整内容，请提交数据导出工单或联系主账号-->
      <!--      </div>-->
      <div class="cell-detail">
        {{ selectedCellDetail.row[selectedCellDetail.column.property] }}
      </div>
      <div class="footer">
        <a-button type="primary" @click="handleDetailCopy">{{ $t('fu-zhi') }}</a-button>
        <a-button @click="handleCloseCellDetailModal">{{ $t('guan-bi') }}</a-button>
      </div>
    </a-modal>
    <Modal v-model="showResourceInfo"
           :title="$t('si-you-ban-bu-shu-xiang-qing')"
           width="1020px"
           footer-hide @on-cancel="handleCloseModal">
      <div class="renew-license-modal">
        <Table size="small" border :columns="resourceColumns" :data="resourceData" style="margin-bottom: 25px;">
          <template slot="authedTaskCount" slot-scope="{ row }">
            {{row.authedTaskCount === -1 ? $t('wu-xian') : row.authedTaskCount}}
          </template>
          <template slot="authedSubAccountCount" slot-scope="{ row }">
            {{row.authedSubAccountCount === -1 ? $t('wu-xian') : row.authedSubAccountCount}}
          </template>
          <template slot="authedTaskTableCount" slot-scope="{ row }">
            {{row.authedSubAccountCount === -1 ? $t('wu-xian') : row.authedTaskTableCount}}
          </template>
          <template slot="timeRange" slot-scope="{row}">
            {{ fecha.format(new Date(row.startTimeMs), 'YYYY-MM-DD HH:mm:ss') }} -
            {{ fecha.format(new Date(row.endTimeMs), 'YYYY-MM-DD HH:mm:ss') }}
            <span>
              {{ $t('sheng-yu-ke-yong-shi-chang-chang') }}
              <strong style="background: #FF8813;border-radius: 2px;color: #fff;padding: 1px 3px;">{{
                  getCcProductClusterList.length>0 ? Math.floor(currentProduct.config.authCode.remainingTimeMs / 1000 / 60 / 60 / 24)
                    : Math.floor(ccGlobalSetting.authCode.remainingTimeMs / 1000 / 60 / 60 / 24)
                }}</strong>{{ $t('tian-0') }}
              <strong style="background: #FF8813;border-radius: 2px;color: #fff;padding: 1px 3px;">{{
                  getCcProductClusterList.length>0 ? Math.floor(currentProduct.config.authCode.remainingTimeMs / 1000 / 60 / 60 %  24)
                    : Math.floor(ccGlobalSetting.authCode.remainingTimeMs / 1000 / 60 / 60 %  24)
                }}</strong>{{ $t('xiao-shi') }}
            </span>
          </template>
        </Table>
        <div class="section" style="margin-bottom: 15px;">
          <div class="title">{{ $t('fu-zhi-shen-qing-ma') }}</div>
          <div class="sub-title">
            {{ $t('fu-zhi-xia-mian-de-shen-qing-ma-jiang-gai-shen-qing-ma-tian-ru-dui-ying-de-di-fang') }}
            <div style="color:#0052D9;" @click="handleCopyApplyCode">
              <img src="../../assets/download.svg"/>
              {{ $t('fu-zhi') }}
            </div>
          </div>
          <div class="apply-code">
            {{ applyCode }}
          </div>
        </div>
        <div class="section" style="margin-bottom: 16px;" v-if="renewLicense">
          <div class="title">{{ $t('shu-ru-xu-ke-zheng') }}</div>
          <div class="sub-title">{{ $t('jiang-guan-wang-shou-quan-ye-mian-sheng-cheng-de-xu-ke-zheng-tian-xie-dao-xia-mian-shu-ru-kuang-dian-ji-ji-huo-wan-cheng-ji-huo') }}</div>
          <div class="license-code">
            <Input v-model="licenseData.authCode" type="textarea"
                   :placeholder="$t('qing-shu-ru-xu-ke-zheng')"/>
          </div>
        </div>
        <div class="section operation">
          <div class="left">
            {{ $t('ji-huo-yu-dao-wen-ti-qing') }}
            <a :href="store.state.contactUsUrl" v-if="!isAsia" target="_blank">{{ $t('lian-xi-ke-fu') }}</a>
            <Poptip placement="right" v-if="isAsia">
              <span style="color: #075DDF;cursor:pointer;">{{ $t('lian-xi-ke-fu') }}</span>
              <div slot="content">
                <img src="@/assets/wechat.png" width="200" height="200">
              </div>
            </Poptip>
          </div>
          <div class="right" style="margin-left: 20px">
            <pd-button type="primary" v-if="!renewLicense" @click="handleRenewLicense">{{ $t('zhong-xin-ji-huo') }}</pd-button>
            <pd-button type="primary" v-else @click="handleActiveLicense">{{ $t('li-ji-ji-huo') }}</pd-button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showRemind"
      :title="$t('xu-ke-zheng-dao-qi-ti-xing')"
      footer-hide
      width="500"
    >
      <div>
        <div style="text-align: center;font-weight: 400;font-size: 14px;line-height: 20px;color: #667085;">
          {{ $t('nin-de-xu-ke-zheng-jiang-yu') }}
          <strong style="color: #FF360C">{{getCcProductClusterList.length>0 && currentProduct.config ? currentProduct.config.authCode ? fecha.format(new Date(currentProduct.config.authCode.endTime), 'YYYY-MM-DD HH:mm:ss') : ''
            : ccGlobalSetting.authCode && ccGlobalSetting.authCode.endTime ? fecha.format(new Date(ccGlobalSetting.authCode.endTime), 'YYYY-MM-DD HH:mm:ss') : ''}}</strong>{{ $t('dao-qi-huan-sheng') }}
          <strong style="color: #FF360C">{{
              getCcProductClusterList.length>0 && currentProduct.config ? Math.floor(currentProduct.config.authCode.remainingTimeMs / 1000 / 60 / 60 / 24)
                : ccGlobalSetting.authCode ? Math.floor(ccGlobalSetting.authCode.remainingTimeMs / 1000 / 60 / 60 / 24) : ''
            }}</strong>
          {{ $t('tian-0') }}
          <strong style="color: #FF360C">{{
              getCcProductClusterList.length>0 && currentProduct.config ? Math.floor(currentProduct.config.authCode.remainingTimeMs / 1000 / 60 / 60 %  24)
                : ccGlobalSetting.authCode ? Math.floor(ccGlobalSetting.authCode.remainingTimeMs / 1000 / 60 / 60 %  24) : ''
            }}</strong>
          {{ $t('xiao-shi-xu-ke-zheng-shi-jian-dao-qi-hou-cloudcanal-kong-zhi-tai-jiang-wu-fa-zhi-hang-zheng-chang-yun-wei-cao-zuo') }}<strong style="color: #FF360C">{{ $t('zheng-zai-yun-hang-de-ren-wu-ye-hui-bei-quan-bu-ting-zhi') }}</strong>{{ $t('qing-zhu-yi') }}
        </div>
        <!--        <p style="margin-top: 10px">-->
        <!--          <Checkbox v-model="confirmRemind">已确认，不再提示</Checkbox>-->
        <!--        </p>-->
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button @click="handleCancel">{{ $t('guan-bi') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showInactiveReason" width="916" :title="$t('xu-ke-zheng-shen-qing')" footer-hide>
      <div class="apply-license-modal">
        <div class="inactive-reason" v-if="inactiveReason" style="font-size:18px;margin-bottom: 20px;margin-left: 40px;">
          <span class="title" style="font-weight: bold">{{ $t('wei-ji-huo-yuan-yin') }}</span>
          <span class="title" style="font-weight: bold;color: red">{{inactiveReason}}</span>
        </div>
        <div class="body">
          <div class="steps">
            <div class="circle">1</div>
            <div class="line1"></div>
            <div class="circle">2</div>
            <div class="line2"></div>
            <div class="circle">3</div>
          </div>
          <div class="content">
            <div class="section" style="margin-bottom: 28px">
              <div class="title">{{ $t('si-you-bu-shu-ban-shen-qing') }}</div>
              <div class="sub-title" style="display: inline-block;">
                {{ $t('si-you-bu-shu-ban-qing-dao') }}<a :href="store.state.docUrlPrefix" target="_blank">{{ $t('clougence-guan-wang') }}</a>{{ $t('zhi-jie-dian-ji-huo-qu-xu-ke-zheng-shang-ye-ban-qing-lian-xi-cloudcanal-gong-zuo-ren-yuan-huo-qu-xu-ke-zheng') }}
              </div>
            </div>
            <div class="section" style="margin-bottom: 15px;">
              <div class="title">{{ $t('fu-zhi-shen-qing-ma') }}</div>
              <div class="sub-title">
                {{ $t('fu-zhi-xia-mian-de-shen-qing-ma-jiang-gai-shen-qing-ma-tian-ru-dui-ying-de-di-fang') }}
                <div style="color:#0052D9;" @click="handleCopyApplyCode">
                  <img src="@/assets/download.svg"/>
                  {{ $t('fu-zhi') }}
                </div>
              </div>
              <div class="apply-code">
                {{ applyCode }}
              </div>
            </div>
            <div class="section">
              <div class="title">{{ $t('shu-ru-xu-ke-zheng') }}</div>
              <div class="sub-title">{{ $t('jiang-guan-wang-shou-quan-ye-mian-sheng-cheng-de-xu-ke-zheng-tian-xie-dao-xia-mian-shu-ru-kuang-dian-ji-ji-huo-wan-cheng-ji-huo') }}
              </div>
              <div class="license-code">
                <Input v-model="licenseData.authCode" type="textarea" style="width: 825px;"
                       :placeholder="$t('qing-shu-ru-xu-ke-zheng')"/>
              </div>
            </div>
            <div class="section tip">
              <div>{{ $t('1-cong-wei-jin-hang-guo-xu-ke-zheng-shen-qing-he-ji-huo') }}</div>
              <div>{{ $t('2-rong-liang-chao-guo-xu-ke-zheng-xian-ding-deedu-shan-chu-duo-yu-de-ren-wu-hou-ke-zi-hang-zhong-xin-ji-huo') }}</div>
              <div>{{ $t('3-xu-ke-zheng-chao-guo-shi-chang-she-qu-ban-ke-yi-zai-guan-wang-zhong-xin-shen-qing-she-qu-ban-xu-ke-zheng-ji-huo-yan-chang-shi-yong-shi-jian') }}</div>
              <div class="link">
                <a :href="`${store.state.docUrlPrefix}/cc-doc/license/license_use`" target="_blank">{{ $t('ji-huo-shou-ce') }}</a>
              </div>
            </div>
            <div class="section" style="display: flex;justify-content: space-between;align-items: center;">
              <div class="left">
                {{ $t('ji-huo-yu-dao-wen-ti-qing') }}
                <a v-if="!isAsia" :href="store.state.contactUsUrl" target="_blank">{{ $t('lian-xi-ke-fu') }}</a>
                <Poptip placement="right" v-if="isAsia">
                  <span style="color: #075DDF;cursor:pointer;">{{ $t('lian-xi-ke-fu') }}</span>
                  <div slot="content">
                    <img src="@/assets/wechat.png" width="200" height="200">
                  </div>
                </Poptip>
              </div>
              <div class="right">
                <pd-button type="primary" @click="handleActiveLicense" style="margin-right: 20px;">{{ $t('ji-huo') }}</pd-button>
                <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import Navbar from '@/components/Navbar';
import { setApprovalProcessMixin, setCloudAKSKMixin, setOpPasswordMixin } from '@/mixins/modal';
import enterOpPwdMixin from '@/mixins/modal/enterOpPwdMixin';
import { CONSOLE_JOB_NAME, EDITIONS_I18N, LANG_I18N } from '@/const';
import XEClipboard from 'xe-clipboard';
import CCWaterMark from '@/components/widgets/CCWaterMark';
import Ping from 'ping.js';
import store from '@/store';
import { queryValidity } from '@/services/cc/api/authCode';
import { handleCopy } from '@/components/function/dataJob/createJob/util';
import fecha from 'fecha';
import { UPDATE_SELECT_PRODUCT_CLUSTER } from '@/store/mutationTypes';
import { createWebSocket, hasWebSocketInstance, webSocketClose } from '@/services/socket';

export default {
  name: 'Home',
  components: {
    CCWaterMark,
    Navbar
  },
  data() {
    return {
      showChild: false,
      showVersionDetailModal: false,
      version: {
        ignore: false,
        newVersion: false,
        prompt: false,
        lastVersion: '',
        detail: []
      },
      isOnline: false,
      showDetailModal: false,
      testHtml: 'test\ntest\n',
      selectedCellDetail: {
        column: {},
        row: {}
      },
      showMenu: false,
      showAccount: false,
      hasMessage: false,
      messageList: [],
      limitDays: 6,
      CONSOLE_JOB_NAME,
      watermarkStr: '',
      currentProduct: '',
      store,
      EDITIONS_I18N,
      LANG_I18N,
      resourceColumns: [
        {
          title: this.$t('ren-wu-shu'),
          slot: 'authedTaskCount',
          width: 80
        },
        {
          title: this.$t('zi-zhang-hao-shu'),
          slot: 'authedSubAccountCount',
          width: 100
        },
        {
          title: this.$t('dan-ren-wu-ke-xuan-ze-biao-shu'),
          slot: 'authedTaskTableCount',
          width: 140
        },
        {
          title: 'LicenseUuid',
          key: 'licenseUuid',
          width: 150
        },
        {
          title: this.$t('yi-shou-quan-shi-jian'),
          slot: 'timeRange'
        }
      ],
      resourceData: [],
      showActiveLicense: false,
      confirmRemind: false,
      showRemind: false,
      showInactiveReason: false,
      showResourceInfo: false,
      inactiveReason: '',
      renewLicense: false,
      licenseData: {},
      applyCode: '',
      macAddress: '',
      cueAuthCode: '',
      fecha
    };
  },
  mixins: [setOpPasswordMixin, setCloudAKSKMixin, setApprovalProcessMixin, enterOpPwdMixin],
  computed: {
    showLicenseSelect() {
      const path = this.$route.path;
      if (path.indexOf('/system') > -1) {
        return false;
      } else if (path.indexOf('/ccdatasource') > -1) {
        return false;
      }

      return true;
    },
    ...mapGetters([
      'isDesktop', 'buildVersion',
      'includesCC',
      'includesDM'
    ]),
    ...mapState(['userInfo', 'myAuth', 'globalSetting', 'productClusterList', 'selectCcProductCluster', 'ccGlobalSetting', 'defaultRedirectUrl']),
    ...mapGetters(['isAsia']),
    showCcProductCluster() {
      return ['taskList', 'taskCreate/process', 'taskEdit/process', 'task/id', 'task/id/params',
        'dataSource', 'dataSource/add', 'data/job/group/id', '/data/job/meta/center', '/monitor/consolelist',
        '/monitor/exception', '/monitor/job/dashboard', 'alarm/log', 'resource', 'resource/id', 'operationLog',
        '/system/fsm', '/system/fsm/id/jobId', '/system/state/task', '/system/state/task/id', '/userCenter',
        '/system/alert/setting', '/system/stTokenSetting', 'operationLog', '/monitor/worker/graph', '/monitor/job/graph'].includes(this.$route.name);
    },
    getCcProductClusterList() {
      const ccList = [];
      this.productClusterList.forEach((cluster) => {
        if (cluster.product === 'CloudCanal') {
          ccList.push(cluster);
        }
      });
      return ccList;
    },
    getDmProductClusterList() {
      const ccList = [];
      this.productClusterList.forEach((cluster) => {
        if (cluster.product === 'CloudDM') {
          ccList.push(cluster);
        }
      });
      return ccList;
    }
  },
  async created() {
    await this.$store.dispatch('getRegionList');

    if (this.globalSetting.features.PRODUCT_CLOUD_CANAL || this.globalSetting.features.PRODUCT_CLOUD_DM) {
      await this.$store.dispatch('getProductList');
    }
    if (this.globalSetting.features.PRODUCT_CLOUD_CANAL) {
      if (this.getCcProductClusterList.length === 0) {
        await this.$store.dispatch('getCcGlobalConfig');
      }
    }
    if (this.globalSetting.features.PRODUCT_CLOUD_DM) {
      if (this.getDmProductClusterList.length === 0) {
        await this.$store.dispatch('getDmGlobalConfig');
      }
    }
    this.showChild = true;
    if (this.includesDM) {
      await this.$store.dispatch('getRegionList');
    }
    const waterMark = await this.$services.watermark();
    this.watermarkStr = `${waterMark.data.user_name}_${waterMark.data.user_phone}`;
    window.$bus.on('setOpPasswordModal', (edit = false) => {
      if (this.globalSetting.authOpPassword) {
        this.setOpPasswordModal(edit);
      }
    });
    window.$bus.on('showCellDetailModal', ({ row, column }) => {
      if (!this.showDetailModal) {
        this.showDetailModal = true;
        this.selectedCellDetail = {
          row,
          column
        };
      }
    });
    window.$bus.on('showEnterOpPwdModal', this.showEnterOpPwdModal);
    window.$bus.on('setCloudAKSKModal', this.setCloudAKSKModal);
    window.$bus.on('dingDingSettingModal', this.setApprovalProcessModal);
    window.$bus.on('showActiveLicenseModal', this.handleshowInactiveReason);
    this.detectOnline();
    if (this.includesDM) {
      await this.checkVersion();
    }
  },
  destroyed() {
    window.$bus.off('setOpPasswordModal');
    window.$bus.off('showEnterOpPwdModal');
    window.$bus.off('setCloudAKSKModal');
    window.$bus.off('dingDingSettingModal');
    window.$bus.off('showCellDetailModal');
    window.$bus.off('showActiveLicenseModal');
  },
  methods: {
    ...mapMutations([UPDATE_SELECT_PRODUCT_CLUSTER]),
    async handleLangChange(value) {
      await localStorage.setItem('lang', value);
      this.$router.go(0);
    },
    handleChangeProductCluster(value) {
      this.productClusterList.forEach((cluster) => {
        if (cluster.clusterCode === value) {
          this.currentProduct = cluster;
          localStorage.setItem('selectCcProductCluster', value);
          this[UPDATE_SELECT_PRODUCT_CLUSTER](value);
          this.$router.go(0);
        }
      });
    },
    handleSelectProduct(data) {
      this.updateSelectProductCluster(data);
      console.log('selectCcProductCluster', this.selectCcProductCluster);
    },
    handleDownload() {
      window.open('https://www.clougence.com/clouddm-personal', 'blank');
      this.handleCloseVersionDetailModal();
    },
    async handleCloseVersionDetailModal() {
      this.showVersionDetailModal = false;
      if (this.version.ignore) {
        this.version.ignore = false;
        await this.$services.versionIgnore();
        await this.checkVersion();
      }
    },
    async checkVersion(showDetailModal = false) {
      const res = await this.$services.versionCheck();
      if (res.success) {
        const { newVersion, prompt } = res.data;
        this.version = {
          newVersion, prompt
        };
        if (prompt || showDetailModal) {
          const res2 = await this.$services.versionDetail();
          if (res2.success) {
            this.version.detail = res2.data.detail;
            this.version.lastVersion = res2.data.lastVersion;
            this.showVersionDetailModal = true;
          }
        }
      }
    },
    detectOnline() {
      const p = new Ping();
      p.ping('https://official-web-resource.oss-cn-hangzhou.aliyuncs.com/wechat-clouddm.png?time=1688610325842')
        .then(() => {
          this.isOnline = true;
        })
        .catch(() => {
          this.isOnline = false;
        });
    },
    /* eslint-disable */
    setupBaiduTongji() {
      var _hmt = _hmt || [];
      _hmt.push(['_requirePlugin', 'UrlChangeTracker', {
        shouldTrackUrlChange: function (newPath, oldPath) {
          return newPath && oldPath;
        }
      }
      ]);
      window._hmt = _hmt; // 修改为window 全局变量
      (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?b86e2e73fd7517d78d5ccdf0cd12384c"; //此处请替换你的站点id
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    },
    /* eslint-enable */
    handleDetailCopy() {
      const { row, column } = this.selectedCellDetail;
      if (XEClipboard.copy(row[column.property])) {
        this.$Message.success(this.$t('fu-zhi-cheng-gong'));
      }
    },
    handleCloseCellDetailModal() {
      this.showDetailModal = false;
    },
    handleGoBackHome() {
      if (this.$route.path !== this.defaultRedirectUrl) {
        this.$router.push({ path: this.defaultRedirectUrl });
      }
    },
    goAsyncJobList() {
      this.$router.push({ name: 'ASYNC_JOB_LIST' });
    },
    async listLastFiveFailedJob() {
      const res = await this.$services.listLastFiveFailedJob();

      if (res.success) {
        if (res.data.length > 0) {
          this.messageList = res.data;
        }
      }
    },
    _setCloudAKSKModal() {
      this.$router.push({ path: '/ccsystem/stTokenSetting' });
    },
    _setApprovalProcessModal() {
      this.$store.dispatch('getUserInfo');
      this.setApprovalProcessModal();
    },
    _setOpPasswordModal() {
      this.$store.dispatch('getUserInfo');
      this.setOpPasswordModal(true);
    },
    goUserConfig() {
      this.$router.push({ name: 'User_Config' });
    },
    hideMenu() {
      this.showMenu = false;
    },
    async logout() {
      const res = await this.$services.logout();

      if (res.success) {
        // await this.$store.commit(UPDATE_USERINFO, {});
        // this.$refs.watermark.removeMaskDiv();
        await this.$router.push({ name: 'Login' });
      }
    },
    handleGoHelp() {
      window.open(`${store.state.docUrlPrefix}/cc-doc/intro/product_intro`);
    },
    handleGoMessage() {
      this.$router.push({ path: '/ccsystem/info_center' }).catch(() => {
      });
    },
    handleGoMessageDetail(id) {
      this.$router.push({ path: `/system/console_job/${id}` });
    },
    goReset() {
      this.$router.push({ path: '/reset' });
    },
    goUserCenter() {
      this.$router.push({ path: '/userCenter' });
    },
    handleCopy(data) {
      const input = document.createElement('textarea');

      input.value = data;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);

      this.$Message.success(this.$t('fu-zhi-cheng-gong'));
    },
    handleshowInactiveReason() {
      this.$services.authCodeGetInactiveResult()
        .then((res) => {
          this.inactiveReason = res.data.msg;
          this.showInactiveReason = true;
        });

      this.handleShowActiveLicense();
    },
    handleShowActiveLicense() {
      this.licenseData = {};
      // this.showActiveLicense = true;
      // this.showInactiveReason = false;
      // this.showResourceInfo = false;
      this.applyCode = '';
      this.$services.authCodeGetApplyCode()
        .then((res) => {
          if (res.success) {
            this.applyCode = res.data;
          }
        });
      this.$services.authCodeGetConsoleMac()
        .then((res) => {
          if (res.success) {
            this.macAddress = res.data;
          }
        });
      this.$services.authCodeGetCurAuthCode()
        .then((res) => {
          if (res.success) {
            this.cueAuthCode = res.data;
          }
        });
    },
    handleCloseModal() {
      this.showActiveLicense = false;
      this.showRemind = false;
      this.showInactiveReason = false;
      this.showResourceInfo = false;
      this.renewLicense = false;
    },
    handleCopyApplyCode() {
      handleCopy(this.applyCode);
      this.$Message.success(this.$t('fu-zhi-cheng-gong'));
    },
    handleRenewLicense() {
      this.renewLicense = true;
    },
    handleActiveLicense() {
      this.$services.authCodeActivate({
        data: {
          authCode: this.licenseData.authCode.split('CloudCanal')[0],
          secondAuthCode: this.licenseData.authCode.split('CloudCanal')[1]
        }
      })
        .then((res) => {
          if (res.success) {
            this.$Message.success(this.$t('ji-huo-cheng-gong'));
            this.handleCloseModal();
            window.location.reload();
          }
        });
    },
    handleCancel() {
      this.handleCloseModal();
      if (this.confirmRemind) {
        this.$services.authCodeOffRemind()
          .then((res) => {
            if (res.success) {
              queryValidity()
                .then((res1) => {
                  if (res1.data.code === '1') {
                    this.$store.commit('getLicenseStatus', res1.data.data);
                  }
                });
            }
          });
      }
    },
    handleShowResourceInfo() {
      if (this.getCcProductClusterList.length > 0 && this.currentProduct.config.authCode.success
        || this.getCcProductClusterList.length === 0 && this.ccGlobalSetting.authCode.success) {
        this.$services.authCodeGetAuthedResourceInfo()
          .then((res) => {
            this.resourceData = [];
            this.resourceData.push(res.data);
            this.showResourceInfo = true;
          });

        this.handleShowActiveLicense();
      } else {
        this.handleshowInactiveReason();
      }
    }
  },
  watch: {
    userInfo(val) {
      if (val.showMessage) {
        this.listLastFiveFailedJob();
      }
    },
    selectCcProductCluster(val) {
      this.productClusterList.forEach((cluster) => {
        if (cluster.clusterCode === this.selectCcProductCluster) {
          this.currentProduct = cluster;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;

  .user-expr-tip {
    width: 100%;
    height: 16px;
    line-height: 16px;
    background: rgba(255, 24, 21, 0.3);
    position: absolute;
    margin-top: 48px;
    text-align: center;
  }

  //overflow: hidden;
  .header {
    background: #0087c7;
    display: flex;
    justify-content: space-between;
    color: white;
    height: 48px;
    line-height: 48px;
    background-position: left;
    background-size: 200px 48px;
    background-repeat: no-repeat;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;

    .ant-menu-dark {
      background: none;
    }

    .brand-container {
      padding: 0 15px;
    }

    .ant-menu-horizontal {
      line-height: 48px;
    }

    .user-info {
      display: flex;
      align-items: center;
      position: relative;

      & > i {
        opacity: 0.7;
        cursor: pointer;
      }

      & > .domain {
        //opacity: 0.7;
      }

      & > *:not(:last-child) {
        margin-right: 20px;
        font-size: 14px;
      }

      .icon-help {
        font-size: 20px;
      }

      .avatar {
        cursor: pointer;
        width: 28px;
        height: 28px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .message-icon {
        position: relative;
        cursor: pointer;

        i {
          opacity: 0.7;
        }

        .message-point {
          position: absolute;
          background: #FF6E0D;
          width: 6px;
          height: 6px;
          display: block;
          border-radius: 50%;
          right: -2px;
          top: 6px;
        }
      }

      .menu {
        z-index: 999;
        position: absolute;
        width: 240px;
        top: 40px;
        right: 0;
        color: #333;
        box-shadow: 1px 1px 6px 0px rgba(164, 164, 164, 0.66);
        background: white;

        .avatar {
          width: 36px;
          height: 36px;
        }

        & > div:not(:last-child) {
          border-bottom: 1px solid rgba(223, 223, 223, 1);
        }

        .one {
          display: flex;
          align-items: center;
          padding: 20px;

          .domain {
            margin-left: 10px;
            font-size: 14px;
          }

          .parent-account-label {
            margin-left: 6px;
          }
        }

        .two {
          padding: 20px;
          line-height: 22px;

          div {
            margin-left: 0;
          }
        }

        .three {
          padding: 15px 24px;

          div {
            cursor: pointer;
            height: 22px;
            line-height: 22px;

            i {
              margin-right: 15px;
            }
          }
        }

        .four {
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #ececec;
          color: #FF6E0D;
          cursor: pointer;
        }
      }
    }
  }

  .footer {
    //height: 24px;
    width: 100%;
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .right {
      display: flex;

      .btn {
        font-size: 12px;
      }
    }

    .async-list {
      position: absolute;
      width: 400px;
      max-height: 400px;
      overflow: auto;
      bottom: 24px;
      border: 1px solid #ccc;
      border-bottom: none;
      z-index: 999;
      background: #fff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      .async-list-header {
        height: 24px;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
      }

      .list {
        padding: 5px;

        .task {
          padding: 3px;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 5px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          width: 100%;

          .content {
            flex: 1;
            min-width: 0;
            margin-right: 40px;

            .title {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 100%;
            }
          }

          .action {
            .async-task-btn + .async-task-btn {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .content-container {
    padding-top: 48px;
    height: 100%;
    overflow-y: auto;
    height: 100%;
  }
}

.ivu-dropdown .ivu-select-dropdown {
  top: 80px !important;
}

.message-list-container {
  width: 360px;
  background: white;
  box-shadow: 1px 1px 6px rgba(164, 164, 164, 0.66);

  .title {
    background: #ECECEC;
    line-height: 50px;
    font-size: 14px;
    font-family: PingFangSC-Semibold;
    font-weight: 500;
    padding: 0 20px;
  }

  .time {
    color: #888888;
  }

  .message-item {
    padding: 20px;
    border-bottom: 1px solid #DFDFDF;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background: #F5F5F5;
    }
  }

  .message-footer {
    padding: 0 20px;
    line-height: 38px;
    cursor: pointer;

    &:hover {
      background: #F5F5F5;
    }
  }
}

.cell-detail {
  white-space: pre-line;
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  max-height: 400px;
  overflow: auto;
}
.renew-license-modal {
  padding: 14px 14px 30px 14px;

  ::v-deep textarea {
    height: 80px;
    width: 100%;
  }

  .operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section {
    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, .9);
      margin-bottom: 8px;
    }

    .sub-title {
      color: rgba(0, 0, 0, .6);
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        img {
          margin-right: 4px;
        }
      }
    }

    .apply-code {
      width: 100%;
      border: 1px solid #DFDFDF;
      word-wrap: break-word;
      padding: 10px;
    }
  }
}

.apply-license-modal {
  width: 100%;
  padding: 14px;

  .body {
    display: flex;

    ::v-deep textarea {
      height: 80px;
    }

    .steps {
      margin-right: 16px;

      .circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid #0052D9;
        font-size: 16px;
        text-align: center;
        line-height: 25px;
        color: #0052D9;
      }

      .line1,
      .line2 {
        width: 1px;
        background: #0052D9;
        margin: 12px;
      }

      .line1 {
        height: 28px;
      }

      .line2 {
        height: 100px;
      }
    }

    .content {
      .tip {
        color: #666666;
        margin-top: 24px;
        width: 825px;
        padding: 15px;
        background: #FAFAFA;
        line-height: 18px;
        margin-bottom: 30px;

        .link {
          color: #075DDF;
          margin-top: 10px;
        }
      }

      .section {
        .title {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, .9);
          margin-bottom: 8px;
        }

        .sub-title {
          color: rgba(0, 0, 0, .6);
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            img {
              margin-right: 4px;
            }
          }
        }

        .apply-code {
          width: 825px;
          border: 1px solid #DFDFDF;
          word-wrap: break-word;
          padding: 10px;
        }
      }
    }
  }
}
.cluster-select {
  width: max-content;
}

.navbar-nav-nb, .navbar-nav-nb-dropdown {
  a {
    font-size: 14px;
  }

  .iconfont {
    font-size: 20px;
  }
}

.navbar-nav-nb, .navbar-nav-nb-dropdown {
  a {
    color: #ffffff;
    opacity: 0.85;

    &:hover {
      opacity: 1;
      color: #ffffff;
    }
  }

  .license-status-span {
    //margin-left: 10px;
  }

  float: right;
  position: relative;

  display: block;
  height: 48px;
  line-height: 48px;
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
  //margin-left: 10px;

  &:hover {
    /*background-color: #f8f8f9;*/
    cursor: pointer;
  }

  .menu-dropdown {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
    position: absolute;
    width: 200px;
    background: #fff;
    right: 0;

    .info {
      border-bottom: 1px solid #dee5e7;
      color: #58666e;
      background-color: #edf1f2;
      padding: 15px;
      margin-bottom: 10px;
    }

    .divider {
      height: 1px;
      margin: 9px 0;
      overflow: hidden;
      background-color: #e5e5e5;
    }

    a {
      height: 30px;
      line-height: 30px;
      display: block;
      padding: 0 10px;
      color: #58666e;

      &:hover {
        background-color: #edf1f2;
      }
    }
  }
}
.navbar-nav-nb-dropdown {
  height: 30px;
  line-height: 30px;
  color: #333;
  a {
    color: #333;
    &:hover {
      opacity: 1;
      color: #333;
    }
  }
}
.layout-logo {
  width: 180px;
  height: 48px;
  display: flex;
  align-items: center;
  background-image: url('~@/assets/logo-BG.png');
  float: left;
  position: relative;
  color: #ffffff;
  padding-left: 20px;
  cursor: pointer;

  .iconfont {
    font-size: 22px;
  }
}

</style>
