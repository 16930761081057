<template>
  <a-menu v-model="currentMenu" mode="horizontal" theme="dark">
<!--    <a-menu-item key="ticket" v-if="!isDesktop && includesDM && myCatLog.includes('CAT_RDP_WORKER_ORDER')"><a href="/#/ticket" >{{ $t('gong-dan') }}</a></a-menu-item>-->
    <a-menu-item key="datajob" v-if="includesCC && myCatLog.includes('CAT_CC_DATAJOB')"><a href="/#/data/job/list" v-if="!isDesktop">{{ $t('tong-bu-ren-wu') }}</a></a-menu-item>
    <a-menu-item key="ccsetting" v-if="includesCC && ccSettingMenuItems.length" >
      <a-dropdown placement="bottomCenter">
        <a :href="ccSettingMenuItems[0].href" v-if="!isDesktop">{{ $t('tong-bu-she-zhi') }}</a>
        <a-menu slot="overlay">
          <a-menu-item v-for="item in ccSettingMenuItems" :key="item.key"><a :href="item.href">{{ item.label }}</a></a-menu-item>
        </a-menu>
      </a-dropdown>
    </a-menu-item>
    <a-menu-item key="sql" v-if="includesDM && myCatLog.includes('CAT_DM_CONSOLE')"><a href="/#/sql">{{ $t('sql-cha-xun') }}</a></a-menu-item>
    <a-menu-item key="ticket" v-if="includesDM && myCatLog.includes('CAT_RDP_WORKER_ORDER') && !isDesktop"><a href="/#/ticket">{{ $t('gong-dan') }}</a></a-menu-item>
    <a-menu-item key="dmsetting" v-if="includesDM && myCatLog.includes('CAT_DM_SYS') && isDesktop">
      <a href="/#/dmdatasource">{{ $t('cha-xun-she-zhi') }}</a>
    </a-menu-item>
    <a-menu-item key="dmsetting" v-if="includesDM && myCatLog.includes('CAT_DM_SYS') && !isDesktop">
      <a-dropdown placement="bottomCenter">
        <a :href="dmSettingUrl">{{ $t('cha-xun-she-zhi') }}</a>
        <a-menu slot="overlay">
          <a-menu-item key="dmdatasource" v-if="myCatLog.includes('CAT_DM_DS')"><a href="/#/dmdatasource">{{ $t('cha-xun-pei-zhi') }}</a></a-menu-item>
          <a-menu-item key="dmmachine" v-if="myCatLog.includes('CAT_DM_WORKER')"><a href="/#/dmmachine">{{ $t('cha-xun-ji-qi') }}</a></a-menu-item>
          <a-menu-item key="dmrulelist" v-if="myCatLog.includes('CAT_DM_SECRULES')"><a href="/#/dmrulelist">{{ $t('an-quan-gui-ze') }}</a></a-menu-item>
          <a-menu-item key="dmspeclist" v-if="myCatLog.includes('CAT_DM_SECRULES')"><a href="/#/dmspeclist">{{ $t('an-quan-gui-fan') }}</a></a-menu-item>
        </a-menu>
      </a-dropdown>
    </a-menu-item>
    <a-menu-item key="ccdatasource" v-if="myCatLog.includes('CAT_RDP_DS')"><a href="/#/ccdatasource">{{ $t('shu-ju-yuan-guan-li') }}</a></a-menu-item>
    <a-menu-item key="system" v-if="myCatLog.includes('CAT_RDP') && !isDesktop"><a :href="getDefaultSystemPath">{{ $t('xi-tong-she-zhi') }}</a></a-menu-item>
  </a-menu>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Navbar',
  data() {
    return {
      currentMenu: ['sql']
    };
  },
  created() {
    this.handlePath();
  },
  computed: {
    ...mapGetters([
      'includesCC',
      'includesDM'
    ]),
    ...mapState(['myCatLog', 'userInfo', 'globalSetting', 'mySystemMenuItems', 'ccSettingMenuItems']),
    ...mapGetters(['isDesktop']),
    getDefaultSystemPath() {
      if (this.mySystemMenuItems.length) {
        return this.mySystemMenuItems[0].href;
      }
      return '/#/userCenter';
    },
    dmSettingUrl() {
      if (this.myCatLog.includes('CAT_DM_DS')) {
        return '/#/dmdatasource'
      } else if (this.myCatLog.includes('CAT_DM_WORKER')) {
        return '/#/dmmachine'
      } else if (this.myCatLog.includes('CAT_DM_SECRULES')) {
        return '/#/dmrulelist'
      }
    }
  },
  methods: {
    handlePath() {
      const path = this.$route.path;
      if (path.indexOf('/sql') > -1) {
        this.currentMenu = ['sql'];
      } else if (path.indexOf('/ticket') > -1) {
        this.currentMenu = ['ticket'];
      } else if (path.indexOf('/system') > -1) {
        this.currentMenu = ['system'];
      } else if (path.indexOf('/data/job') > -1) {
        this.currentMenu = ['datajob'];
      } else if (path.indexOf('ccsetting') > -1 || path.indexOf('/monitor') > -1 || path.indexOf('/alarm') > -1 || path.indexOf('/ccsystem') > -1) {
        this.currentMenu = ['ccsetting'];
      } else if ((path.indexOf('/ccdatasource') > -1)) {
        this.currentMenu = ['ccdatasource'];
      } else if (path.indexOf('/dmdatasource') > -1 || path.indexOf('/dmmachine') > -1 || path.indexOf('/dmrulelist') > -1 || path.indexOf('/dmspeclist') > -1 || path.indexOf('/dmspec') > -1 || path.indexOf('/dmrule') > -1) {
        this.currentMenu = ['dmsetting'];
      } else {
        this.currentMenu = [];
      }
    }
  },
  watch: {
    isDesktop(val) {
      if (val) {
        this.handlePath();
      }
    },
    $route() {
      this.handlePath();
    }
  }
};
</script>

<style scoped>

</style>
